import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#f36c21",
    },
    secondary: {
      main: "#4a83c9",
    },
  },
});

export default mainTheme;
