///Other file was getting to big. This function will get a littler large
///  so put it in it's own file here.
///[PURPOSE] To send the new participant over to API Gateway, which forwards
///  it to Lamba, and then gets sent to WEX.
//import { API } from "aws-amplify";

/*
async function sendToWex(data, companyData, dynamoCompanyData) {
  const apiName = "portal";
  const path = "/wex/createuser";
  const fileContents = _getFileContents(data, companyData, dynamoCompanyData);
  const empCode = companyData.empCode;
  const myInit = {
    body: {
      fileContents: fileContents,
      clientName: empCode,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers":
        "X-API-Key,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
    },
    response: true,
  };

  API.post(apiName, path, myInit)
    .then((response) => {
      if (response.status === 200) {
        return true;
      } else {
        //error occurred, more than likely at API level in lambda
        return false;
      }
    })
    .catch((error) => {
      //error occurred, more than likely at javascript level here
      return false;
    });
}
*/

export function getWexFooter(empCode, wexDate, wexTime) {
  const footer =
    "FF|" + //FF for file footer
    "2|" + //record count - always 2 here - one EN and one PT row
    "034|" + //administrative code - always 034 for Addventure
    `${empCode}|` + //emp code
    `${wexDate}|` + //Date submitted
    `${wexTime}` + //Time submitted
    "";
  return footer;
}
export function getWexHeader(empCode, wexDate, wexTime) {
  const header =
    "FH|" + //FH for file header
    "034|" + //034 is the Addventure code - same for all people
    `${empCode}|` + //employer code
    "N|" + //synchronize the data? Always N for No
    `${wexDate}|` + //submitted date (now) MMDDYYYY
    `${wexTime}|` + //time submitted (now) HHMMSS
    "3.5"; //API version for Wex - always this value
  return header;
}

//Get a single EN row
export function getSingleEnrollmentRow(wexDate, planName) {
  const singleRow =
    "EN|" + //Record type - (EN for Enrollment)
    '%%ADDVENTURE_ID%%' +
    "|" + //Participant file import ID
    planName + "|" + //plan Name
    wexDate +
    "|" + //Enrollment Effective Day - default to today
    "|" + //participant election amount - not required
    "|" + //Enrollment Termination Date - not required
    "|" + //Employer Contribution Level - not required
    "|" + //Employer Contribution Amount - not required
    "|" + //Primary Reimbursement - not required
    "|" + //Alternate reimbursement - not required
    "|" + //enrolled in claims exchange - not required
    "|" + //election amount indicator - not required
    "|" + //HDHP Coverage level - not required
    "|" + //Plan Year Start Date - not required
    "|" + //Terms & Conditions accepted - not required
    "|" + //Date Terms accepted - not required
    "|" + //Time terms accepted - not required
    ""; //Action - not required
  return singleRow;
}

//get the PT row that goes to WEX
export function getPTRow(city, addrState, zip, email, dob, address1, lastName, firstName, wexToday, payFreq) {
  const singleRow =
    "PT|" + //Record Type (Participant - new user!)
    '%%ADDVENTURE_ID%%' +
    "|" + //Participant File Import ID
    '%%ADDVENTURE_ID%%' +
    "|" + //Employer Employee ID
    '%%ADDVENTURE_ID%%' +
    "|" + //Employee Number
    lastName + "|" + //Last name
    firstName + "|" + //First name
    "|" + //Middle Initial - not required
    "|" + //gender - not required
    "|" + //marital status - not required
    "|" + //mothers maiden name - not required (we should not supply this anyway on principal)
    dob +
    "|" + //Date of Birth - MMDDYYYY format
    "|" + //SSN - not required
    address1 + "|" + //address line 1
    "|" + //address line 2 - not required
    "|" + //address line 3 - not required
    "|" + //address line 4 - not required - who has 4 lines in a damn address though?
    city + "|" + //city
    addrState + "|" + //state
    zip + "|" + //zip
    "US|" + //hardcoded to USA
    "|" + //home phone - not required
    "|" + //work phone - not required
    "|" + //work phone extension - not required
    "|" + //email address - not required
    email +
    "|" + //username - set to email
    "ChangeMe123!|" + //password
    wexToday +
    "|" + //hire data - default to today
    "|" + //division - not required
    "|" + //hours per week - not required
    "All|" + //employee class - not required. Class of employee? low class
    payFreq + "|" + //payroll frequency - gotten from company data
    "|" + //payroll effective date - not required
    "Active|" + //participant status - must be active since we are onboarding them
    wexToday + "|" + //status effective date - let's use today
    "|" + //hold payroll deductions - not required
    "|" + //hold employee contributions - not required
    "|" + //incur services - not required
    "|" + //final payroll process date
    "|" + //final contribution process date
    "|" + //HSA custodian - not required
    "|" + //medicare beneficiary - not required
    "|" + //medicare ID - not required
    "|" + //exchange integration ID - not required
    "|" + //reporting hierarchy, level 1 - not required
    "|" + //reporting hierarchy, level 2 - not required
    "|" + //reporting hierarchy, level 3 - not required
    "|" + //reporting hierarchy, level 4 - not required
    "|" + //CDD citizenship - not required
    "|" + //CDD country - not required
    "|" + //CDD employment status - not required
    "|" + //CDD employer - not required
    "|" + //CDD job title - not required
    "|" + //class effective date - not required
    "|" + //ID identification type - not required
    "|" + //ID identification number - not required
    "|" + //ID issuing state - not required
    "|" + //ID issuer
    "|" + //Id issue date - not required
    "|" + //ID expiration date - not required
    "|" + //mobile carrier - not required
    "|" + //mobile number - not required
    ""; //time zone - not required, but no | at end because no field after
  return singleRow;
}

//We store DOB in value like 'November 12, 1999' but need it in MMDDYYYY
export function getDobInWexFormat(longDate) {
  const newDate = new Date(longDate);
  const day = newDate.getDate().toString().padStart(2, "0");
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear();
  const strDate = `${month}${day}${year}`;
  return strDate;
}

export function formatTodayToWexFormat() {
  var newDate = new Date();
  const day = newDate.getDate().toString().padStart(2, "0");
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear();
  return `${month}${day}${year}`;
}

//Wex wants it in HHMMSS
export function formatNowTimeInWexFormat() {
  const newDate = new Date();
  const hour = newDate.getHours().toString().padStart(2, "0");
  const minutes = newDate.getMinutes().toString().padStart(2, "0");
  const seconds = newDate.getSeconds().toString().padStart(2, "0");

  return `${hour}${minutes}${seconds}`;
}
