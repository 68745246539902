import { Paper, Link, Box } from "@mui/material";
import { React } from "react";
import { useLocation } from "react-router-dom";

import "../home.scss";

import bgLogo from "../images/addventure-logo-white-PRINT.png";

const LastPage = (props) => {
  const location = useLocation();
  const shouldBeHere = location?.state?.shouldBeHere;
  if (shouldBeHere === null || shouldBeHere === undefined) {
    return (
      <div className="container">
        <main>
          <img src={bgLogo} alt="logo" className="addLogo" />
          <Paper className="formCard">
            <div className="orangeText">You cannot go directly to this URL</div>
            <br />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                paddingBottom: "75px",
                paddingTop: "50px;",
              }}
            >
              <Link href="/" color="secondary">
                <b>Go home</b>
              </Link>
            </Box>
          </Paper>
        </main>
      </div>
    );
  }
  return (
    <div className="container">
      <img src={bgLogo} alt="logo" className="addLogo" />
      <Paper
        className="lastCard"
        sc={{
          minWidth: ["66%", "100%", "100%"],
        }}
      >
        <div className="orangeText">Let's Go Places</div>
        <div className="blackText">You are all set!</div>
        <div className="blackTextSmall">
        You are now enrolled in Addventure. Party. Set off those fireworks. 
        Celebrate!
          <br />
          <br />
          Please look for an email inviting you to download the Addventure app from 
          your favorite app store. The app is the best way to set travel goals and track 
          your savings. If you have questions, we can be reached from within the app or by emailing us at:
        </div>
        <Link href="mailto:support@goaddventure.com" className="supportLink">
          support@goaddventure.com
        </Link>

        <br />
        <br />
        <br />
      </Paper>
    </div>
  );
};

export default LastPage;
