//so we don't really need to put this in a gitignore. Because ReactJS is a
//  client-side app, we really have no way to secure them from ever being
//  viewed. We take steps to mitigate it by putting it in this file but even
//  if we put it in an ENV variable, it would need to be read on the client
//  side of things, which is the end-user's browser. And that means it could
//  be viewed by anyone who has tools to do so.
//
// [TLDR] Don't put overly sensitive info in here thinking it is secure
const config = {
  apiGateway: {
    URL: "https://obhx5t0m8h.execute-api.us-east-1.amazonaws.com/prod",
  },
  xApiKey: "kyw8VJ7o5y9xZ2eF9ewmB7c3RaGsZo8b9eVVmIgQ",
};

export default config;
