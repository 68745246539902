import "../home.scss";
import {
  Paper,
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
} from "@mui/material";
import stateCodes from "../constants/addrStates";
import dobMonth from "../constants/dobMonth";
import dobDay from "../constants/dobDay";

import bgLogo from "../images/addventure-logo-white-PRINT.png";
import { useState } from "react";
import SubmitButton from "../components/submitButton";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { 
  formatNowTimeInWexFormat, 
  formatTodayToWexFormat, 
  getDobInWexFormat, 
  getPTRow, 
  getSingleEnrollmentRow, 
  getWexHeader,
  getWexFooter 
} from "../helpers/sendToWex";
import { useNavigate } from "react-router-dom";

const getFirstNameFromFullName = (fullName) => {
  if (fullName.indexOf(" ") === -1) {
    return "";
  }
  const firstName = fullName.substring(0, fullName.indexOf(" "));
  return firstName;
};

const getLastNameFromFullName = (fullName) => {
  if (fullName.indexOf(" ") === -1) {
    return "";
  }
  const lastName = fullName.substring(fullName.indexOf(" ") + 1);
  return lastName;
};

const isDobValid = (dob) => {
  if (dob == null) {
    return false;
  }
  if (dob.length < 1) {
    return false;
  }
  const arrDob = dob.split(",");
  if (arrDob.length !== 2) {
    return false;
  }
  return true;
};

const getMonthFromDob = (dob) => {
  if (isDobValid(dob) === false) {
    return "";
  }
  const month = dob.substring(0, dob.indexOf(" "));
  return month;
};

const getDayFromDob = (dob) => {
  if (isDobValid(dob) === false) {
    return "";
  }
  const day = dob.split(" ")[1].replace(",", "");
  if (day.startsWith("0")) {
    return day[1];
  }
  return day;
};

const getYearFromDob = (dob) => {
  if (isDobValid(dob) === false) {
    return "";
  }
  const year = dob.split(" ")[2];
  return year;
};

const SecondStep = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
  const companyData = location?.state?.companyData;
  const [addressState, setAddressState] = useState(userData?.state ?? "");
  const [month, setMonth] = useState(
    getMonthFromDob(userData?.birthDate ?? "")
  );
  const [day, setDay] = useState(getDayFromDob(userData?.birthDate ?? ""));
  const [showSpinner, setShowSpinner] = useState(false);
  const oldEmail = userData?.sk; //need this to tie together in case user changes email
  const [showMoney, setShowMoney] = useState(userData?.ee_contribution?.length > 0);

  const moneyAdornment = showMoney
    ? {
        startAdornment: (
          <InputAdornment position="start" hidden={showMoney}>$</InputAdornment>
        ),
        inputProps: {
          max: 300.00,
          min: 1.00,
          step: 0.01,
        },
      }
    : {
      inputProps: {
        max: 300.00,
        min: 1.00,
        step: 0.01,
      },
    };

  //user clicked the submit button...
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSpinner(true);
    const dob = getDobInWexFormat(`${month} ${day}, ${event.target.year.value}`);
    const wexDateNow = formatTodayToWexFormat();
    const wexTimeNow = formatNowTimeInWexFormat();
    const headerRow = getWexHeader(userData.empCode, wexDateNow, wexTimeNow);
    const footerRow = getWexFooter(userData.empCode, wexDateNow, wexTimeNow);
    const participantRow = getPTRow(event.target.city.value, 
      addressState, 
      event.target.zipCode.value, 
      event.target.email.value, 
      dob, 
      event.target.addressLine1.value, 
      event.target.lastName.value, 
      event.target.firstName.value,
      wexDateNow,
      companyData.payFreq
      );
    const enrollmentRow = getSingleEnrollmentRow(wexDateNow, companyData.planName);
    const fileContents = `${headerRow}\r\n${participantRow}\r\n${enrollmentRow}\r\n${footerRow}`;
    const apiName = "portal";
    const path = "/adduser";
    const myInit = {
      body: {
        oldEmail: oldEmail,
        company: event.target.company.value,
        email: event.target.email.value,
        first_name: event.target.firstName.value,
        last_name: event.target.lastName.value,
        phone: "+1" + event.target.cellPhone.value,
        address1: event.target.addressLine1.value,
        city: event.target.city.value,
        state: addressState,
        zip: event.target.zipCode.value,
        birthDate: `${month} ${day}, ${event.target.year.value}`,
        emp_code: userData.empCode,
        eeNo: userData.eeNo,
        ee_contribution: event.target.eeContribution.value,
        fileContents: fileContents
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers":
          "X-API-Key,Access-Control-Allow-Origin,Access-Control-Allow-Headers,X-Amz-Target",
      },
      response: true,
    };

    API.post(apiName, path, myInit)
      .then(async (response) => {
        if (response.statusCode === 200 || response.status === 200) {
          if (response.data.errorType === "UsernameExistsException") {
            console.log("Username already exists!");
            alert(
              "This email already exists. Could you be already registered?"
            );
            return;
          }

          navigate("/welcome", { state: { shouldBeHere: true } });
        } else {
          console.log(response);
          alert(
            "There was an error and we did not add the user. Problem is: " +
              response?.data?.errorMessage
          );
        }
      })
      .catch((error) => {
        alert("We did not add the user because we hit an error of: " + error);
        console.log(error);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };
  if (userData === null || userData === undefined) {
    return (
      <div className="container">
        <main>
          <img src={bgLogo} alt="logo" className="addLogo" />
          <Paper className="formCard" sx={{ backgroundColor: "#ffffff99" }}>
            <div className="orangeText">You cannot go directly to this URL</div>
            <br />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                paddingBottom: "75px",
                paddingTop: "50px;",
              }}
            >
              <Link href="/" color="secondary">
                <b>Go home</b>
              </Link>
            </Box>
          </Paper>
        </main>
      </div>
    );
  }
  return (
    <div className="container">
      <main>
        <img src={bgLogo} alt="logo" className="addLogo" />
        <Paper
          className="formCard"
          sx={{ maxWidth: ["95%", "80%", "60%"], backgroundColor: "#ffffffee" }}
        >
          <div className="orangeText">To enroll, complete the below form</div>
          <br />
          <form onSubmit={handleSubmit} className="dataForm">
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                marginTop: "15px",
              }}
            >
              <TextField
                label="First Name"
                defaultValue={getFirstNameFromFullName(userData?.name)}
                variant="outlined"
                fullWidth
                name="firstName"
                id="firstName"
                required
                margin="normal"
                autoComplete="on"
                sx={{
                  marginRight: "20px",
                }}
              />
              <TextField
                label="Last Name"
                defaultValue={getLastNameFromFullName(userData?.name)}
                variant="outlined"
                fullWidth
                name="lastName"
                id="lastName"
                required
                margin="normal"
                autoComplete="on"
              />
            </Box>
            <TextField
              label="Email"
              defaultValue={oldEmail}
              variant="outlined"
              fullWidth
              type="email"
              name="email"
              id="email"
              required
              margin="normal"
              autoComplete="on"
              helperText="This will be your username"
            />
            <TextField
              label="Cell Phone"
              variant="outlined"
              fullWidth
              defaultValue={userData.contact_phone}
              name="cellPhone"
              id="cellPhone"
              required
              margin="normal"
              autoComplete="off"
              helperText="Must be a cell phone that can receive texts"
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  minLength: 10,
                },
              }}
            />
            <FormControlLabel
              value="end"
              control={<Checkbox id="enrolled" required />}
              label="You consent to receive a one-time passcode via SMS when logging in."
              labelPlacement="end"
            ></FormControlLabel>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              defaultValue={userData.address1}
              name="addressLine1"
              id="addressLine1"
              required
              margin="normal"
              autoComplete="off"
              helperText="We will mail you a physical card. Please ensure accuracy"
            />
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                marginTop: "15px",
              }}
            >
              <TextField
                label="City"
                defaultValue={userData.city}
                variant="outlined"
                fullWidth
                name="city"
                id="city"
                required
                margin="none"
                autoComplete="off"
              />

              <TextField
                select
                label="State"
                variant="outlined"
                name="addrState"
                id="addrState"
                defaultValue={userData.state}
                required
                value={addressState}
                onChange={(e) => setAddressState(e.target.value)}
                sx={{
                  width: "150px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <MenuItem disabled value="">
                  <em>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </em>
                </MenuItem>
                {stateCodes.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Zip"
                variant="outlined"
                name="zipCode"
                id="zipCode"
                defaultValue={userData.zip}
                required
                margin="none"
                autoComplete="off"
                InputProps={{
                  inputProps: {
                    minLength: 5,
                    maxLength: 5,
                  },
                }}
              />
            </Box>
            <TextField
              label="Company"
              variant="outlined"
              fullWidth
              value={companyData.sk}
              disabled
              name="company"
              id="company"
              required
              margin="normal"
              autoComplete="on"
              sx={{
                marginTop: "20px",
              }}
            />
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                marginTop: "15px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "200px" }}>Date of Birth:</div>
              <TextField
                select
                label="Month"
                variant="outlined"
                name="month"
                id="month"
                required
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                sx={{
                  width: "300px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <MenuItem disabled value="">
                  <em>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </em>
                </MenuItem>
                {dobMonth.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Day"
                variant="outlined"
                name="day"
                id="day"
                defaultValue={getDayFromDob(userData.birthDate)}
                required
                value={day}
                onChange={(e) => setDay(e.target.value)}
                sx={{
                  width: "150px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                <MenuItem disabled value="">
                  <em>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </em>
                </MenuItem>
                {dobDay.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Year"
                variant="outlined"
                fullWidth
                name="year"
                id="year"
                defaultValue={getYearFromDob(userData.birthDate)}
                required
                margin="none"
                type="number"
                autoComplete="off"
                InputProps={{
                  inputProps: {
                    max: 2010,
                    min: 1900,
                  },
                }}
              />
            </Box>
            <TextField
              label="Employee Contribution Amount Per Paycheck"
              variant="outlined"
              fullWidth
              defaultValue={userData.ee_contribution}
              name="eeContribution"
              id="eeContribution"
              required
              margin="normal"
              autoComplete="off"
              type="number"
              helperText="Between $1.00-300.00. Can be changed later. If you want more than $300, contact Addventure."
              InputProps={
                moneyAdornment
              }
              onChange={(e) => setShowMoney(e.target.value.length > 0)}
            />
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                marginTop: "15px",
                alignItems: "center",
                marginBottom: "-20px",
              }}
            >
              <FormControlLabel
                value="end"
                control={<Checkbox id="acceptTos" required />}
                label="You have read and accepted the"
                labelPlacement="end"
                required
              ></FormControlLabel>
              <Link
                href="https://www.goaddventure.com/terms-of-service"
                target="_blank"
                sx={{
                  marginLeft: "-10px",
                }}
              >
                Terms and Conditions
              </Link>
            </Box>
            {<SubmitButton btnText="Submit" isLoading={showSpinner} />}
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Link href={`mailto:${companyData.contact_email}`}>
                Contact {companyData.sk} HR
              </Link>

              <Link href="mailto:support@goaddventure.com">
                Contact Addventure
              </Link>
            </Box>
            <br />
            <br />
            <br />
          </form>
        </Paper>
        <br />
        <br />
        <br />
      </main>
    </div>
  );
};

export default SecondStep;
