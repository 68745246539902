import "./App.css";
import VerifyToken from "./pages/home";
import SecondStep from "./pages/second-step";
import LastPage from "./pages/last-page";
import { ThemeProvider } from "@mui/material/styles";
import mainTheme from "./theme";

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="wrapper">
      <ThemeProvider theme={mainTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<VerifyToken />} />
            <Route path="/data" element={<SecondStep />} />
            <Route path="welcome" element={<LastPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
