import { Paper, TextField } from "@mui/material";
import { React, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import "../home.scss";
import SubmitButton from "../components/submitButton";

import bgLogo from "../images/addventure-logo-white-PRINT.png";

const VerifyToken = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [checkError, setCheckError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSpinner(true);

    const apiName = "portal";
    const path = "/token";
    const myInit = {
      body: {
        email: email,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers":
          "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
      },
      response: true,
    };
    API.post(apiName, path, myInit)
      .then((response) => {
        if (response.status === 200) {
          navigate("/data", {
            state: {
              userData: response.data.user,
              companyData: response.data.company,
            },
          });
        } else {
          setCheckError("We hit an unknown error. Please try again");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setCheckError("The email was not found. Please check and try again.");
        } else {
          setCheckError("We hit an unknown error. Please try again.");
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  return (
    <>
      <img src={bgLogo} alt="logo" className="addLogo" />
      <Paper
        className="firstCard"
        sx={{ maxWidth: ["95%", "80%", "60%"], backgroundColor: "#ffffffcc" }}
      >
        <div className="orangeText">WELCOME TO ADDVENTURE</div>
        <div className="blackText">ENTER YOUR EMAIL</div>

        <form onSubmit={handleSubmit} className="dataForm">
          <br />

          <TextField
            type="text"
            value={email}
            required
            fullWidth
            name="email"
            label="Email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />

          <br />
          {<SubmitButton btnText="Submit" isLoading={showSpinner} />}
          {checkError !== null && checkError.length > 0 && (
            <div className="errorText">{checkError}</div>
          )}
        </form>
      </Paper>
    </>
  );
};

export default VerifyToken;
